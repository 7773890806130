<template>
  <v-list nav>
    <v-list-item-group>
      <template>
        <v-list-item :to="{ name: 'Home' }">
          <v-list-item-action>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>

      <v-list-item v-if="vueTocs" :to="{ name: 'Tocs' }">
        <v-list-item-action>
          <v-icon>settings</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Tocs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueUsuarios" :to="{ name: 'Usuarios' }">
        <v-list-item-action>
          <v-icon>person_pin</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.usuario", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vuePermissoes" :to="{ name: 'Permissoes' }">
        <v-list-item-action>
          <v-icon>assignment</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.permissao", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueAuditoria" :to="{ name: 'Audits' }">
        <v-list-item-action>
          <v-icon>security</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.auditoria") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuSistema",
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTocs() {
      return this.getAccess("vueTocs", "menu");
    },
    vueUsuarios() {
      return this.getAccess("vueUsuarios", "menu");
    },
    vuePermissoes() {
      return this.getAccess("vuePermissoes", "menu");
    },
    vueAuditoria() {
      return this.getAccess("vueAuditoria", "menu");
    },
  },
};
</script>

<style></style>
